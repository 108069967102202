import React from 'react';
import styles from './styles.module.scss';
import Sys_Success_20 from '@/assets/icons/Sys_Success_20.svg';

const LoginModal = () => {
    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modal}>
                <div className={styles.modalHeader}>
                    <Sys_Success_20 />
                    <div className={styles.headerText}>Авторизация прошла успешно</div>
                </div>
                <div className={styles.modalText}>Перенаправляем на главную страницу...</div>
            </div>
        </div>
    );
};

export { LoginModal };
