import React from 'react';
import LoginPage from '@/components/Pages/LoginPage';
import { AuthContextProvider } from '@/components/AuthContext';

const Index = () => (
    <AuthContextProvider>
        <LoginPage />
    </AuthContextProvider>
);

export default Index;
