import React, { Component, useRef, useState, useEffect } from 'react';
import SEO from '@/components/seo';
import Header from '@/components/Header';
import { navigate } from 'gatsby';
import useOnClickOutside from 'use-onclickoutside';
import cx from 'classnames';
import MQ from 'react-responsive';
import InputMask from 'react-input-mask';
import { login, altLogin } from '@/services/auth';
import { Section } from '@/components/UI/Section';
import { StyledButton } from '@/components/UI/Button';
import CollapseTransition from '@/components/UI/CollapseTransition';
import styles from './styles.module.scss';
import { LoginModal } from './units';
import { digitsOnly } from '../../../utils';
import { DASHBOARD_ROUTE } from '../../../consts';
import { AuthContext } from '@/components/AuthContext';
import TagManager from 'react-gtm-module';
import axios from 'axios';

const tagManagerArgs = {
    gtmId: 'GTM-WZT6KWQ'
};

function isPhone(phoneValue) {
    const v = phoneValue.replace(/\D+/g, '');
    const phoneRegexp = /^\d{11}$/;
    return !!phoneRegexp.test(v);
}

const HEAD_TEXT = 'Авторизуйтесь по номеру телефона';
const HEAD_TEXT_AWAIT = 'Подтвердите вход на экране телефона';

const MOCK_ANSWER = 'Для вашего номера доступ не предоставлен. Обратитесь к ответственному presale в вашем филиале';
const MOCK_ALT_FAIL = 'Неверный логин или пароль.';
const MOB_ID = 'Войти с мобильным ID';
const LOG_PASS = 'Войти через логин';

const Counter = ({ count = 15, disabledPush }) => {
    const seconds = count < 10 ? '0' + count : count;

    if (disabledPush) return '00:' + seconds;
    return null;
};

const LoginPopup = ({
    onSubmit,
    onClose,
    phoneValue,
    passwordValue,
    onChange,
    onUsernameChange,
    onPassChange,
    loginFailed,
    awaiting,
    onAltSubmit,
    altForm,
    showAltForm
}) => {
    const ref = useRef(null);
    useOnClickOutside(ref, onClose);
    const image = require('@/images/mobile-id-icon.png');

    const [disabledPush, setDisabledPush] = useState(true);
    const [count, setCount] = useState(15);

    const timerDecrement = () => {
        if (disabledPush && awaiting && count > 0) {
            setCount(count - 1);
        }

        if (awaiting && count <= 0) {
            setDisabledPush(false);
        }
    };

    useEffect(() => {
        setTimeout(() => timerDecrement(), 1000);
    }, [count]);

    useEffect(() => {
        timerDecrement();
    }, [awaiting]);

    const repeatSubmit = (e) => {
        e.preventDefault();
        setDisabledPush(true);
        setCount(15);
    };

    return (
        <form ref={ref} className={styles.login}>
            {altForm ? (
                <div className={styles.alterWrap}>
                    <input onChange={onUsernameChange} placeholder={'Логин'} className={styles.inputAlter} />
                    <input
                        type="password"
                        onChange={onPassChange}
                        placeholder={'Пароль'}
                        className={styles.inputAlter}
                    />
                    <button onClick={onAltSubmit} className={styles.altLoginBtn}>
                        Войти с помощью логина и пароля
                    </button>

                    {loginFailed && <div className={styles.hint}>{MOCK_ALT_FAIL}</div>}
                </div>
            ) : (
                <>
                    {awaiting && isPhone(phoneValue) ? (
                        <>
                            <div className={styles.mobileHead}>{HEAD_TEXT_AWAIT}</div>
                            {!loginFailed && (
                                <div className={styles.hintPush}>
                                    Мегафон отправил PUSH
                                    <br /> на номер: <b>{phoneValue}</b>
                                </div>
                            )}
                            <StyledButton
                                noBorder
                                onClick={repeatSubmit}
                                className={disabledPush ? styles.loginBtnDisabled : styles.loginBtnMobile}>
                                <div className={styles.loginMobile}>
                                    <span className={styles.textLoginBtn}>
                                        Отправить Push еще раз <Counter {...{ count, disabledPush }} />
                                    </span>
                                </div>
                            </StyledButton>
                        </>
                    ) : (
                        <>
                            <div className={styles.mobileHead}>{HEAD_TEXT}</div>
                            <InputMask
                                autoFocus
                                type={'text'}
                                className={styles.input}
                                placeholder={'+7 (___) ___-__-__'}
                                mask="+7 (999) 999-99-99"
                                value={phoneValue}
                                onChange={onChange}
                            />

                            <StyledButton noBorder onClick={onSubmit} className={styles.loginBtnMobile}>
                                <div className={styles.loginMobile}>
                                    <img className={styles.imageLogo} src={image} />
                                    <span className={styles.textLoginBtn}>Войти с Мобильным ID</span>
                                </div>
                            </StyledButton>
                            {loginFailed && <div className={styles.hint}>{MOCK_ANSWER}</div>}
                        </>
                    )}
                </>
            )}

            <div className={styles.linkedHint} onClick={showAltForm}>
                {altForm ? MOB_ID : LOG_PASS}
            </div>
        </form>
    );
};

class LoginPage extends Component {
    state = {
        isLoginFormVisible: false,
        isPhoneValid: false,
        phoneValue: '',
        loginFailed: false,
        awaiting: false,
        altForm: false,
        userName: '',
        password: '',
        modalAwait: false
    };

    showAltForm = () => {
        this.setState({
            altForm: !this.state.altForm
        });
    };

    onChange = (e) => {
        this.setState({
            phoneValue: e.target.value,
            loginFailed: false,
            awaiting: false
        });
    };

    onUsernameChange = (e) => {
        this.setState({
            userName: e.target.value,
            loginFailed: false
        });
    };

    onPassChange = (e) => {
        this.setState({
            password: e.target.value,
            loginFailed: false
        });
    };

    onSubmit = async (e) => {
        e.preventDefault();
        const { setIsLoggedIn } = this.props;
        const { phoneValue, passwordValue } = this.state;
        const isPhoneValid = isPhone(phoneValue);
        // const isPassValid = passwordValue.length > 1;
        this.setState({ isPhoneValid, awaiting: true });

        if (isPhoneValid) {
            const response = await login({
                username: digitsOnly(phoneValue),
                password: ' ',
                awaiting: false
            });
            if (response.status === 200) {
                this.setState({ modalAwait: true });

                setTimeout(() => {
                    setIsLoggedIn(true);
                    this.setState({ modalAwait: false });
                }, 2000);
            } else {
                this.setState({ loginFailed: true, awaiting: false });
            }
        }
    };

    onAltSubmit = async (e) => {
        e.preventDefault();

        const { setIsLoggedIn } = this.props;
        const { userName, password } = this.state;

        const response = await altLogin({
            username: userName,
            password: password,
            awaiting: false
        });
        if (response.status === 200) {
            this.setState({ modalAwait: true });

            setTimeout(() => {
                setIsLoggedIn(true);
                this.setState({ modalAwait: false });
            }, 2000);
        } else {
            this.setState({ loginFailed: true, awaiting: false });
        }
    };

    toggleLoginForm = () => {
        const { isLoginFormVisible } = this.state;
        this.setState({ isLoginFormVisible: !isLoginFormVisible });
        if (isLoginFormVisible) {
            this.$video.play();
        } else {
            this.$video.pause();
        }
    };

    handleLogingUpdate = () => {
        const { isLoggedIn } = this.props;
        if (isLoggedIn) {
            navigate(DASHBOARD_ROUTE);
        }
    };

    componentDidUpdate(prevProps) {
        const { wasLoggedIn } = prevProps;
        const { isLoggedIn } = this.props;

        if (wasLoggedIn !== isLoggedIn) {
            this.handleLogingUpdate();
        }
    }

    componentDidMount() {
        this.handleLogingUpdate();
        TagManager.initialize(tagManagerArgs);
    }

    render() {
        const {
            phoneValue,
            passwordValue,
            isLoginFormVisible,
            loginFailed,
            awaiting,
            altForm,
            modalAwait
        } = this.state;

        return (
            <div>
                {modalAwait && <LoginModal />}
                <Header isLoginPage onLoginClick={this.toggleLoginForm} />
                <SEO title="Megafon" />
                <Section className={styles.root}>
                    <div className={styles.videoContainer}>
                        <MQ orientation="portrait">
                            <video
                                ref={(ref) => (this.$video = ref)}
                                className={cx(styles.video, isLoginFormVisible && '_paused')}
                                src={require('@/assets/video/mobile_src.mp4')}
                                poster={require('@/assets/images/intro_video_poster.jpg')}
                                autoPlay
                                playsInline
                                muted
                                loop
                            />
                        </MQ>
                        <MQ orientation="landscape">
                            <video
                                ref={(ref) => (this.$video = ref)}
                                className={cx(styles.video, isLoginFormVisible && '_paused')}
                                src={require('@/assets/video/desktop_src.mp4')}
                                poster={require('@/assets/images/intro_video_poster.jpg')}
                                autoPlay
                                playsInline
                                muted
                                loop
                            />
                        </MQ>
                    </div>
                    <div className={styles.inner}>
                        <CollapseTransition in={isLoginFormVisible}>
                            <LoginPopup
                                onClose={this.toggleLoginForm}
                                phoneValue={phoneValue}
                                passwordValue={passwordValue}
                                onChange={this.onChange}
                                onPassChange={this.onPassChange}
                                onSubmit={this.onSubmit}
                                loginFailed={loginFailed}
                                awaiting={awaiting}
                                altForm={altForm}
                                showAltForm={this.showAltForm}
                                onUsernameChange={this.onUsernameChange}
                                onAltSubmit={this.onAltSubmit}
                            />
                        </CollapseTransition>
                    </div>
                </Section>
            </div>
        );
    }
}

const LoginPageWithContext = () => (
    <AuthContext.Consumer>
        {([isLoggedIn, setIsLoggedIn]) => <LoginPage isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />}
    </AuthContext.Consumer>
);

export default LoginPageWithContext;
